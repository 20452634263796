* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* home page */

.productsOfMonthSection {
  background-color: #dbd9c0;
}

/* about page */
.aboutIcon svg {
  color: #006535;
  font-size: 2em;
}

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  width: 80%;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.775em !important;
  font-weight: 600;
  text-align: left !important;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: justify !important;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #006535 !important;
  color: #fff;
  font-size: 1em;
}

/* product detail pages */
.productPage {
  background-color: #dbd9c0;
}

.productDetailImg {
  height: 250px !important;
  object-fit: contain !important;
}

.productDetailSubImg {
  height: 100px !important;
  object-fit: cover !important;
}

.productDetailTitle {
  color: #757575;
  font-size: 25px;
}

.productWeight {
  color: #757575;
  font-size: 12px;
}

.ingrediants li {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 3px;
}

.ratingDiv button {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 26%);
  border: none;
  font-size: 14px;
  color: #212121;
  padding: 10px 26px;
  cursor: pointer;
}

/* cart page */
.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (min-width: 1025px) {
  .h-custom {
    height: 100vh !important;
  }
}

/* profile page */
.addressDiv button {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: green;
  font-size: 0.9em;
  font-weight: 400;
  letter-spacing: 0.5px;
  outline: none;
  padding: 1% 3% 1% 1%;
  text-transform: uppercase;
  text-align: left;
  width: 100%;
}

.addressDiv svg {
  font-size: 1.3em;
  font-weight: 500;
}

.list-group-item {
  cursor: pointer;
}

.profileIntroDiv {
  text-align: center;
  min-height: 420px;
}

.profileIntroDiv img {
  height: 300px;
}

.profileIntroDiv p {
  font-size: 1em;
  font-weight: 400;
  text-transform: capitalize;
}

.loginGoogleBtn {
  border: 1px solid #e0e0e0;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  .slider {
    height: 180px !important;
  }
  .productTitle {
    font-size: 0.6em !important;
    font-weight: 600 !important;
  }
}

/* privacy policy page */
.privacyPolicy p {
  text-align: justify;
  font-size: 1rem;
}

.privacyPolicy h4 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.3rem;
}

/* terms page */
.terms h4 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.3rem;
}

.terms p {
  text-align: justify;
  font-size: 1rem;
}
